body-xls, html-xls {
	margin: 0px auto;
	padding: 0px;
	background: #fff;
	font-family: Arial;
	font-weight: 400;
	font-size: 1em;
	color: #777777;
	height: 100%;
	line-height: 1.8;
}

// Color shorthand
.lightblue {
    color: #0B8BB0;
}

.page-title-xls .title-text{
  color: #000000;
	font-family: Arial;
  	text-align: center;
	font-size: 1.2em;
}

.report-list-xls li.report-group.first, 
.report-list-xls span.report-group.first {
  border-top: 0px solid #000000;
}

.report-table-xls {
	font-family: Arial;
	font-weight: normal;
	font-size: 1em;
}

.report-table-xls thead tr th {
  color: #000000;
	font-family: Arial;
	font-weight: normal;
	font-size: 1em;
  padding: 5px 0px 0px 0px;
  text-align: center;
  margin: 0px;
  border-bottom: 1px solid #000000;
}

.report-table-xls tbody tr td {
  color: #000000;
	font-family: Arial;
	font-weight: normal;
	font-size: 1em;
}

.report-table-xls tbody td.total {
    border-top: 1px double #000000;
}
