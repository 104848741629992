/* Hierarchic lists (reports, areas, etc.) */
.hier-list {
  line-height: 150%;
  list-style: none;
}

.hier-list-h1 {
  color: $text-header-color;
  font-size: 1.15em;
  font-weight: 700;
  text-align: left;
  margin-bottom: .75em;
}

.hier-item {
  color: $text-item-color;
  font-size: 1em;
  font-weight: 400;
  text-align: left;
  padding: 0;
  margin-top: 0.25em;
}

.hier-group-l1 {
  @extend .hier-item;
  color: $text-header-color;
  font-weight: 700;
  border-top: 1px solid $border-header-color;
/*  margin-top: 1em;*/
}

.hier-group-l1.first {
  border-top: none;
}

.hier-item-l1 {
  @extend .hier-item;
}

.hier-group-l2 {
  @extend .hier-group-l1;
  border-top: none;
  margin-left: 0.5em;
}

.hier-item-l2 {
  @extend .hier-item-l1;
  margin-left: 0.5em;
}

.hier-group-l3 {
  @extend .hier-group-l1;
  border-top: none;
  margin-left: 1em;
}

.hier-item-l3 {
  @extend .hier-item-l1;
  margin-left: 1em;
}

.hier-group-l4 {
  @extend .hier-group-l1;
  border-top: none;
  margin-left: 1.5em;
}

.hier-item-l4 {
  @extend .hier-item-l1;
  margin-left: 1.5em;
}

.hier-subitem-l4 {
  @extend .hier-item-l4;
  margin-left: 2.75em;
  list-style-type: circle;
}

.hier-h1 {
  @extend .hier-item;
  color: $text-header-color;
  font-weight: 700;
  border-top: 1px solid $border-header-color;
  margin-top: 1em;
}

.hier-h1.first {
  border-top: none;
}

.hier-h2 {
  @extend .hier-item;
  color: $text-header-color;
  font-weight: 700;
  margin-left: 0.5em;
}

.hier-h3 {
  @extend .hier-item;
  margin-left: 1em;
  font-weight: bolder;
}

.hier-first {
  margin-top: 0em;
  padding-top: 0em;
}

.hier-item a {
  text-decoration: none;
}

.hier-item-h1 {
  margin-left: 0em;
  padding-left: 1em;
}

.hier-item-h2 {
  margin-left: 0.5em;
  padding-left: 0.5em;
}

.hier-item-h3 {
  margin-left: 1em;
  padding-left: 0.75em;
}

.hier-item a:hover {
  color: $text-hover-color;
}
