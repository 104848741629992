
// Search box
.search-form .searchbox {
  /*margin-top: 70px;*/
  padding-top: 25px;
  width: 300px;
}

.searchbox .input-group-btn .search-scope, 
.searchbox .input-group-btn .dropdown-menu, 
.searchbox .input-group-btn .dropdown-menu a, 
.searchbox .input-group-btn .search-submit {
  /*background-color: #ffa34d;*/
  background-color: #fabb78;
  /*border: 1px solid #ffa44d;*/
  border: 1px solid #fabb78;
  color: #076782;
  box-shadow: 0 1px 2px #87B6C3;
  font-weight: 400;
}

.searchbox .input-group-btn .dropdown-menu,
.searchbox .input-group-btn .dropdown-menu a {
	padding-left: 0.5rem;
	min-width: 6rem;
	box-shadow: 0 1px 2px #fabb78;
}

.searchbox .form-control {
  /*border: 1px solid #ffa44d;*/
  border: 1px solid #fabb78;
  box-shadow: 0 1px 2px #87B6C3;
}

// Quick search box
ul.quick-search {
  border: 1px solid #0b8bb0;
  margin-left: 1em;
  margin-right: 1em;
}

.quicksearch li {
  color: #333;
}

.quick-search li.list-group-item {
  border: none;
  width: 100%;
}

.quick-search li.report-group, 
.quick-search li.report-sub-group,
.quick-search li.report-sub-item,
.quick-search li.report-action-item {
  font-size: 1em;
  font-weight: normal;
  padding-top: 0.05em;
  padding-right: 0.5em;
  padding-left: 0.5em;
  padding-bottom: 0.05em;
  margin: 0 0 0 0;
  border-top: none;
  text-align: left;
}

.quick-search li.report-group, 
.quick-search li.report-sub-group {
  color: #777777;
}

.quick-search li.report-sub-group {
  padding-left: 1em;
  font-style: italic;
}

.quick-search li.report-sub-item {
  padding-left: 1.5em;
  font-weight: bold;
}

.quick-search li.report-action-item {
  padding-left: 0.5em;
  font-weight: bold;
  border-top: 1px solid #0b8bb0;
}

.quick-search li.report-sub-group a,
.quick-search li.report-sub-item a,
.quick-search li.report-action-item a {
  text-decoration: none;
  color: #333;
}

.quick-search li.report-sub-item:hover,
.quick-search li.report-action-item:hover {
  background-color: #ccecfc;
  color: #333;
}

.quick-search a.btn {
  width: 100%;
  /*margin-top: 2em;*/
}

.quick-search .card-base {
  width: 100%;
  margin-top: 2em;
}