// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');

// Icons
/*@import "node_modules/\@fortawesome/fontawesome-pro/scss/fontawesome.scss";
@import "node_modules/\@fortawesome/fontawesome-pro/scss/light.scss";
@import "node_modules/\@fortawesome/fontawesome-pro/scss/regular.scss";
@import "node_modules/\@fortawesome/fontawesome-pro/scss/solid.scss";
*/
// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';

// Urbics
@import "body";
@import "buttons";
@import "card";
@import "urbics";
@import "nav";
@import "welcome";
@import "home";
@import "search";
@import "login";
@import "notifications";
@import "sidebar";
@import "filter";
@import "report";
@import "hier-list";
@import "toolbar";
@import "footer";
@import "helper";
@import "admin";
@import "excel";
@import "register";
@import "settings";
@import "menu";
@import "vue-select/dist/vue-select.css";
@import "stripe";
@import "mapbox";
@import "tabs-component";
