body, html {
	margin: 0px auto;
	padding: 0px;
	background: #fff;
	font-family: 'Open Sans', sans-serif;
	font-weight: 400;
	color: $body-color;
	font-size: 0.9em;
  height: 100%;
  line-height: 1.8;
}

// Color shorthand
.lightblue {
    color: #0B8BB0;
}