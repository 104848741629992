#welcome #header #loginbox {
  margin-top: 50px;
}
#loginbox form .form-control {
  background-color: #FFFFFF;
  color: #0B8BB0;
  border: 1px solid #87B6C3;
  border-radius: 4px;	
}
#loginbox form label {
  color: #0B8BB0;
  font-weight: normal;
}
#loginbox form .btn-primary {
  background-color: #87B6C3;
  color: #ffffff;
}
#loginbox form .btn-primary:hover {
  background-color: #008eaa;
}
#loginbox form a.btn-link {
  color: #0B8BB0;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
}

#loginbox form a:hover {
  color: #FF9F43;
  text-decoration: none;
}
