// Typography
$font-family-sans-serif: 'Nunito', sans-serif;
$font-size-base: 0.9rem;
$line-height-base: 1.6;

// Colors
$blue: #3490dc;
$indigo: #6574cd;
$purple: #9561e2;
$pink: #f66d9b;
$red: #e3342f;
$orange: #f6993f;
$yellow: #ffed4a;
$green: #38c172;
$teal: #4dc0b5;
$cyan: #6cb2eb;
$light-gray: #c2c8cc;
$black: #000000;

// Body
$body-bg: #f5f8fa;
$body-color: #212529;
$body-color-gray: #777777;
$hover-color: #FFC68A;
$hover-color-darker: #F7921D;
$hover-color-darkest: #eb8b2c;
$site-text: #333333;
$site-text-gray: #636b6f;
$site-white: #ffffff;
$site-whitesmoke: #f5f5f5;
$site-yellow: #fed05c;
$site-yellow-pale: lighten($site-yellow, 20%);
$site-orange: $hover-color-darker;
$site-orange-pale: $hover-color;
$site-magenta: #b12383;
$site-magenta-pale: lighten($site-magenta, 20%);
$site-green: #28a744;
$site-green-pale: lighten($site-green, 20%);
$site-blue: #0B8BB0;
$site-blue-pale: #87B6C3; 
$site-blue-paler: #A7CDD8;
$site-blue-ghost: #F0F8FF;
$site-blue-dark: #076782;
$site-blue-dark-alt: #04607A;
// $site-blue-dark-alt: #00008B;
$site-purple: #6e4698;
$site-purple-pale: lighten($site-purple, 25%);
$site-black: #00000B;

// Base Border Color
$spark-border-color: darken($body-bg, 10%);
$border-radius-base: 4px;
$border-header-color:  $site-blue;
$border-header-color-pale: lightgray;
$border-header-color-darker: #757575;

// Typography
$text-color: $site-text-gray;
$text-color-darker: $site-text;
$text-header-color: $site-blue;
$text-link-color: $site-blue-dark;
$text-item-color: $site-text;
$text-item-color-old: #808080;
$text-hover-color: $hover-color-darker;
$text-hover-color-old: $site-blue;

// Brands
$brand-primary: #3097D1;

// Nav
$nav-background-color: $site-blue-pale; 
$nav-background-color-pale: $site-blue-paler; 
$nav-background-color-white: $body-bg; 
$nav-hover-color: $hover-color;
$nav-link-color: $site-blue-dark-alt; 
/*$nav-link-color: $site-black;*/
$toolbar-background-color: $site-whitesmoke;
$toolbar-nav-hover-color: $hover-color-darkest;

// Nav-tabs
$nav-tabs-active-background-color: $site-blue-paler; 
$nav-tabs-hover-color: $hover-color;

// Alert background colors
$alert-bg-primary: #cfe2ff;
$alert-bg-secondary: #e2e3e5;
$alert-bg-success: #d1e7dd;
$alert-bg-danger: #f8d7da;
$alert-bg-warning: #fff3cd;
$alert-bg-info: #cff4fc;
$alert-bg-light: #fefefe;
$alert-bg-dark: #d3d3d4;
$alert-bg-status: #d1e7dd;
