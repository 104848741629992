/* CSS specific to Settings pages */

.urbics-settings-content {
  width: 100%;
}

.card-settings {
	@extend .card-register;
/*  background-color: $site-blue-ghost;
  border: 1px solid $site-blue-pale;
  margin-top: 1rem; */
  padding-bottom: 1rem;
}

.card-settings form {
	padding-bottom: 1.25rem;
}

.card-settings button.btn {
	color: $body-color-gray;
}

.card-settings .card-header {
	margin-bottom: 1rem;
	color: $body-color-gray;
	/*font-weight: bolder;*/
	font-size: 1.25rem;
}

.card-inset {
	@extend .card-register;
	margin-top: 1rem;
}

.card-settings .card-header .card-item {
	margin-bottom: 0.5rem;
	/*font-weight: bolder;*/
	font-size: 1rem;
}

.card-controls .group-header {
	font-size: 1rem;
	font-style: italic;
}

.card-settings .card-sub-header {
	font-size: 0.9rem;
	margin-bottom: 0rem;
}


.card-settings .card-body {
	margin-bottom: 0.5rem;
	/*font-weight: bolder;*/
	font-size: 1rem;
}

.card-settings .card-item-icon {
	padding: 0rem 1rem;
}

.card-settings .card-item {
	margin: 0.5rem 0;
}

/*.card-item-icon i {
	padding-left: 0.5rem;
	vertical-align: middle;
}
*/
.card-settings .card-item-group-header {
	@extend .card-item;
	font-weight: bolder;
	font-style: italic;
}

.card-controls-group-header {
	font-weight: normal;
	font-size: 1rem;
	font-style: italic;
	text-decoration: underline;
	margin-bottom: 0rem;
}

.card-settings .card-column-header {
	margin: 0.5rem 0;
	border-bottom: solid 1px;
	border-color: $site-blue-pale;
}

.card-settings .info-text {
    text-align: center;
    vertical-align: middle;
}

.card-settings .card-controls .card-item {
	font-weight: normal;
	font-size: 0.9rem;
}

p.card-line {
	margin-bottom: 0rem;
}