// Menu

.btn.dropdown-toggle {
    width:  100%;
}

.dropdown-menu .report-group, 
.dropdown-menu .report-sub-group,
.dropdown-menu .report-sub-item {
	color: #777777;
	font-size: 1em;
    font-weight: normal;
    padding-top: 0.05em;
    padding-right: 0.5em;
    padding-left: 0.5em;
    padding-bottom: 0.05em;
    margin: 0 0 0 0;
    border-top: none;
    text-align: left;	
}

.dropdown-menu .report-sub-group {
    padding-left: 1em;
    font-style:  italic;
}

.dropdown-menu .report-sub-group.disabled {
    pointer-events: none;
    background-color: #ffffff;
}

.dropdown-menu .report-sub-item {
    padding-left: 1.5em;
    font-weight:  bold;
}

.dropdown-menu .report-sub-item a {
	color:  #333333;
	text-decoration: none;
}

