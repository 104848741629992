.pricing-card-layout {
	/*padding: 115px 0 20px 0;*/
	padding: 4rem 0 2rem 0;
}

.pricing-card-layout-content:not(:last-of-type) {
    margin-right: 20px;
}

.pricing-card-title, .pricing-card-action {
    font-size: 1.5em;
    font-weight: 600 !important;
    line-height: 24px !important;
    margin-bottom: 1em;
    text-align: left;
    margin-left: 10px;
    padding-top: 20px !important;
    color: #0b8bb0;
    padding-bottom: .5em;
    border-bottom: 1px solid #0b8bb0;
}

.pricing-card-action {
    margin-bottom: 0em;
    padding-bottom: 1em;
    border-bottom: none;
}

.pricing-card-text {
	padding: 0;
	width: 100%;
}

.pricing-card .price-quote {
    padding: 1.0rem 0rem;
    border-bottom: 1px solid #c2c8cc;
}

.pricing-card .price-quote-no-border {
    padding: 1.0rem 0rem;
    border-bottom: 0px;
}

.pricing-card #cc-instructions {
    margin:  1rem 0rem;
    padding: 0rem 1rem;
}

.pricing-card .check-color {
    color: $site-green;
}

.pricing-card .submit-button .btn {
    width: 100%;
    margin: 1rem 0.5rem;
}

.pricing-card .fa-li, #clickableIcon {
    color: $site-green;
}

.pricing-card .price-block {
    margin: 1rem 0;
}

.StripeElement {
    margin-top: 1rem;
}

.billing-plan {
    padding: 0rem 1rem;
}

.billing-plan .feature-list li {
  border-top: none;
}

#clickableIcon {
    cursor: pointer;
}

.detail-dropdown {
    border: 1px solid $light-gray;
    padding: 0.5rem 1rem;
    margin-top: 0.5rem;
}

.detail-dropdown .feature-list li {
    padding: 0.15rem 1.5rem;
}

#register .form-actions {
    margin-top: 3rem;
}

.card-register {
    @extend .card-base;
/*  background-color: $site-blue-ghost;
  border: 1px solid $site-blue-pale;
  margin-top: 1rem;
*/}

.card-register .info-text {
    text-align: center;
    vertical-align: middle;
}