// Home page 
.with-navbar .inner-page {
  margin-top: -50px;
}

.page-wrapper {
  margin-top: 10px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 10px;
}

.page-title {
  margin-top: 30px;
  margin-left: auto;
  margin-right: auto;
  /*border-bottom: 1px solid #0B8BB0;*/
  padding-bottom: 15px;
}

.page-title .title-text {
  line-height: 150%;
  color: #0B8BB0;
  text-align: center;
  font-size: 1.5rem;
  /*text-align: left;*/
  /*font-size: 1.6em;*/
}

.group-title {
  color: #0B8BB0;
  font-size: 1em;
  font-weight: bold;
  padding: 0px;
  margin: 0px;
}

.group-item {
  color: #777777;
}

.page-content {
  /*padding: 15px 0px 0px 0px;*/
  padding: 0px 0px 0px 0px;
}