/* Bootstrap Card customization */

.card-base {
/*  background-color: $site-blue-ghost;
  border: 1px solid $site-blue-pale;
  margin-top: 1rem;
*/
}

.report-card {
	@extend .pricing-card;
}

.report-cards {
	@extend .pricing-cards;
}

.report-card .group-header {
  color: $text-header-color;
  font-size: 1em;
  font-weight: 700;
  padding-top: 0.5em;
  padding-right: 0px;
  padding-left: 0em;
  padding-bottom: 0.5em;
  margin: 0.5em 0 0 0;
  border-top: 1px solid $border-header-color;
  text-align: left;
}