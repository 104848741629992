#modal-notifications {
  .modal-header {
    background: #fff;
    border-bottom: 0;
    margin-bottom: 0;
    height: 70px;
    width: 350px;
    position: fixed;
    z-index: 100;

    .group-header {
      width: 100%;
    }

    .btn-group {
      padding-top: 4px;
      width: 100%;
    }
  }

  .modal-dialog {
    height: 100vh;
    min-height: 100vh;
    color: $site-text-gray;
    font-size: 0.9rem;
    font-weight: 400; 
  }

  .modal-content {
    background: $gray-100;
    border: none;
    overflow-y: scroll;
    height: 100vh;
    min-height: 100vh;
  }

  .modal-body {
    padding: 0;
    height: 100vh;
    min-height: 100vh;
  }

  .modal-footer {
    background: #fff;
    border-top: 1px dashed rgba(0, 0, 0, .1);
    width: 350px;
  }

  .notification-container:first-child {
    padding-top: 70px;
  }

  .notification-container:last-child {
    padding-bottom: 65px + 23px;
  }

  .notification:not(:first-child) {
    padding-top: 25px;
  }

  .notification:not(:last-child) {
    margin-bottom: 23px;
  }

  .notification {
    position: relative;
    padding: 20px 15px 0px 15px;
    border-top: 1px dashed $gray-300;
  }

  figure {
    position: absolute;
  }

  .notification-content {
    padding-left: 70px;

    .meta {
      display: flex;
      align-content: flex-end;
    }

    .title {
      flex: 1;
      font-weight: bold;
      line-height: 1.2;
      margin: 2px 0 10px;
    }

    .date {
      color: $gray-400;
    }

    .notification-body {
      margin-bottom: 15px;
    }
  }

  .spark-profile-photo {
    height: 52px;
    width: 52px;
  }

  .fa-stack {
    font-size: 26px;
  }

  h4 {
    margin-bottom: 15px;
    margin-top: 3px;
  }
}

.modal.docked .modal-dialog {
  position: fixed;
  top: 0;
  margin: 0;
  height: 100vh;
  width: 350px;
}

.modal.docked.docked-left .modal-dialog {
  left: 0;
}

.modal.docked.docked-right .modal-dialog {
  right: 0;
}

.modal.docked .modal-content {
  border-radius: 0;
  height: 100vh;
}

.modal.docked .modal-footer {
  position: fixed;
  bottom: 0;
  right: 0;
}
  