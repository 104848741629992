// Welcome page 
.with-navbar #welcome{
  margin-top: -50px;
}

.header {
  height: 155px;
  margin: 0px -15px 0px -15px;
  padding: 0px;
  background-image: url('/img/home-header-long.png');
  background-position: top;
  background-repeat: no-repeat;
  background-size: auto auto;
}

#title .header {
  @extend .header;
}

#welcome #main {
  line-height: 150%;
}	       

#welcome #main #title { 
  margin-top: 1rem;
//  padding-top: 0px;
//  padding-right: 2%;
//  padding-left: 0px;
//  padding-bottom: 0px;
//  width: 66%;
//  float: left;
//  clear: both;
}

#welcome #main #title p.introtext    {
  font-size: 1.6rem;
  line-height: 150%;
  text-align: left;
  color: #87B6C3;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

#welcome #main #title p.introtext-center {
  @extend .introtext;
  text-align: center;
}

#welcome #main #sidecontact {
//  margin-top: 0px;
//  margin-left: 0px;
//  float: left;
  color: #87B6C3;
}
#welcome #main #sidecontact p.questions {
  margin-top: 1rem;
  margin-bottom: 1rem;
}
#welcome #main #sidecontact a, #oldloginbox a {
  text-decoration:none;
//  padding:0px;
//  margin:0px;
  text-align: center;
  color: #0B8BB0;
}
#welcome #main #sidecontact a:hover, #oldloginbox a:hover {
  text-decoration:none;
  padding:0px;
  margin:0px;
  text-align: center;
  color:#F97A00;
}

// Welcome page header stylings
#welcome h20 {
  font-style: normal;
  font-weight: 400;
  color: #0B8BB0;
  background-image: url('/img/orangebox.gif');
  background-repeat: no-repeat;
  background-size: auto 100%;
  margin-right: 1rem;
  font-size: 1.6rem;
}
#sidecontact h6 {
  width: 120px;
  background-color: #FFCE9F;
  font-size: 1.5rem;
  font-weight: lighter;
  color: #0B8BB0;
  padding: 12px 10px 8px 10px;
  margin: 0px;
  letter-spacing: 0.33em;
  border-radius: 5px;
}                
#welcome h1 {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 2rem;
  color: #87B6C3;
  margin: 0;
  padding:0;
  text-align: center;
}

.highlight-text {
 font-size: 1.3rem;
  line-height: 150%;
  text-align: left;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.highlight-text a {
  font-size: 1.6rem;
  color: #0B8BB0;
  text-decoration: none;
  margin-left: 1rem;
}

.highlight-text a:hover {
  color: $text-hover-color;
}

.callouts {
  margin-top: 3rem;
}

.callout {
  text-align: center;
}

.callout .btn-outline-secondary {
  color: $site-blue-dark-alt;
  border-color: $site-blue-paler;
}

.callout .btn-outline-secondary:hover {
  background-color: $site-blue-paler;
  color: $site-blue-dark-alt;
}

.callout-icon {
  @extend .callout;
  margin-bottom: 1rem;
}

.callout-icon-float-left {
  @extend .callout;
  padding-left: 1rem;
  margin-right: 1rem;
  margin-bottom: 0rem;
  float: left;
}

.callout-timely {
  @extend .callout-icon;
  color: $site-purple-pale;
}

.callout-coverage {
  @extend .callout-icon;
  color: $site-blue-pale;
}

.callout-metrics {
  @extend .callout-icon;
  color: $site-orange-pale;
}

.callout-refresh {
  @extend .callout-icon;
  color: $site-magenta-pale;
}

.callout-depth {
  @extend .callout-icon;
  color: $site-yellow;
}

.callout-plans {
  @extend .callout-icon;
  color: $site-green-pale;
}

.callout-text {
  color: $site-text;
  font-size: 1.2rem;
  line-height: 1.6;
}

.intro .callout-text {
  font-weight: bolder;
  padding-right: 1rem;
}

.intro .callout-detail {
  color: $site-text;
  text-align: left;
  font-size: 1rem;
  font-weight: lighter;
  line-height: 1.5;
  padding-left: 1rem;
}

.intro .callout-detail a {
  color: $nav-link-color;
}

.intro .callout-detail a:hover {
  color: $hover-color;
}

.intro .callouts img.sample-page {
  height: 10rem;
}

.container-pricing {
  margin: 2rem auto 1rem auto;
}

.pricing-cards {
}

.pricing-card {
  @extend .card;
}

.pricing-card-group {
  @extend .card-group;
}
.pricing-card .top-half {
  padding-bottom: 0px;
  text-align: center;
}

.pricing-card .bottom-half {
  padding-top: 0px;
}

.plan-highlight .price {

}

.price>span {
  display: block;
}

.price>span.subscription {
  font-weight: bold;
  font-size: 1.5rem;
  margin: 0.5rem 0rem 0.75rem;
}

.pricing-card .feature-list {
  padding: 0;
  list-style: none;
}

.pricing-card .feature-list li {
  margin-bottom: 0;
  padding: .5rem 1.5rem;
  border-top: 1px solid #c2c8cc;
  transition-property: background-color;
  transition-duration: 0.25s;
  transition-timing-function: ease;
  transition-delay: 0s;

}

.pricing-card .feature-list li.active {
  background-color: #f3f0ee;
}

.pricing-card .feature-list li.last {
  border-bottom: 1px solid #c2c8cc;
}

.plan-detail a.btn {
  width: 100%;
  margin-bottom: 1.5rem;
}

.plan-action {
  margin-top: 3.0rem;
}

.plan-action .btn-min-width {
  min-width: 12rem;
}
