// Helper classes

.padLeft, 
.pad-left,
.padLeftDouble,
.pad-left-double {
  text-align: left;
  padding-left: 1em;
}

.padRight,
.pad-right,
.padRightDouble,
.pad-right-double {
  text-align: right;
  padding-right: 1em;
}

.padLeftDouble,
.pad-left-double {
	padding-left: 2em;
}

.padRightDouble,
.pad-right-double {
	padding-right: 2em;
}

.padBottom,
.pad-bottom {
	padding-bottom: 1em;
}

.padTop,
.pad-top {
	padding-top: 1em;
}

.padBottomDouble,
.pad-bottom-double {
	padding-bottom: 2em;
}

.padTopDouble,
.pad-top-double {
	padding-top: 2em;
}

.shim-y {
	margin: 1em 0;
}

.shim-y-2 {
	margin: 2em 0;
}

.shim-x {
	margin: 0 1em;
}

.shim-x-2 {
	margin: 0 2em;
}

.shim-top {
	margin-top: 1em;
}

.shim-top-2 {
	margin-top: 2em;
}

.shim-bottom {
	margin-bottom: 1em;
}

.shim-bottom-2 {
	margin-bottom: 2em;
}

.no-bottom-line {
  border-bottom: 0px;  
}

/* Width */
.w-33 {
	width: 33%;
}

.w-80 {
	width: 80%;
}

.w-90 {
	width: 90%;
}