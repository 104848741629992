/* Toolbars */
.toolbar-site {
  background-color: $nav-background-color;
  border-right: 1px solid #0B8BB0;
  border-left: 1px solid #0B8BB0;
  border-bottom: 1px solid #0B8BB0;
}

.toolbar-site .dropdown-header {
    font-size: inherit;
    color: inherit;
}

.toolbar-site .dropdown-item:hover, 
.toolbar-site .dropdown-item:focus {
  color: $nav-hover-color;
  background-color: darkgray;  
}

/* Toolbar */
.toolbar {
  line-height: 150%;
  margin-bottom: 1em;
  /*margin-top: -15px;*/
}

.toolbar .navbar-nav > li > a,
.toolbar .navbar-nav > li > span {
  padding-top: 0.5rem;
  padding-bottom: 0.2rem;
  /*color: #076782;*/
  /*height: 2rem;*/
/*  padding-top: 0.3rem;
  padding-bottom: 0.3rem;
*/}

.nav > li > span.pager-link {
  position: relative;
  display: block;
}

.toolbar .navbar-nav .nav-link:hover {
  /*color: white;*/
  color: $nav-hover-color;
}

.toolbar ~ .section-title {
  margin-top: 1em;
  margin-bottom: 0em;
}

.tab-pane .toolbar {
  margin-top: 0;
}

.toolbar-report {
  @extend .toolbar;
  margin-top: 0rem;
  border: 1px solid  $site-blue;
  background-color: $toolbar-background-color;
  padding: 0rem 0rem;
  min-height: 3rem;
}

.tab-pane .toolbar-report {
  border-top: none;
}

.toolbar-report ~ .section-title {
  margin-bottom: 1rem;
}

.toolbar-standalone-alert {
  @extend .toolbar-report;
  margin-bottom: 1rem;
}

.toolbar-report ~ .no-section-title {
  margin-top: 1em;
  margin-bottom: 0em;
}


.toolbar-report ul li.dropdown {
	/*padding-bottom: 0.2rem;*/
}

.toolbar-report ul li.page-item {
  padding-bottom: 0.3rem;
}

.toolbar-report .alert {
  margin-bottom: 0rem;
}

.nav > li > a.pager-link,
.nav > li > span.pager-link {
/*  font-size: 1.125rem;
  font-weight: 600;
*/  color: $nav-link-color;
    padding-top: 0.7rem;
    padding-right: 0.5rem;
    padding-left: 0.5rem;

/*  padding-left: 0.5rem;
  padding-right: 0.5rem;
*/}

.nav > li.disabled > span.pager-link {
  color: $body-color-gray;
}

.nav > li > a.pager-link.last,
.nav > li > span.pager-link.last
 {
    padding-right: 2rem;
}

.toolbar-report .dropdown-menu {
  // @extend .dropdown-menu;
  background-color: $toolbar-background-color;
   border: 1px solid  $site-blue;
}

.toolbar-report .dropdown-item:hover {
  color: $toolbar-nav-hover-color;
}

.toolbar-report .dropdown-item:focus {
  color: $nav-hover-color;
  background-color: darkgray;  
}

li.toolbar-title {
  font-size: 1.25rem;
  padding-top: 0.3rem;
  padding-bottom: 0.3rem;
  color: #076782;
}

.toolbar-alert-success {
  @extend .alert-success;
  color: $text-color;
  background-color: $alert-bg-success;
  /*padding: 0.5rem 1rem;*/
  margin-bottom: 0rem;
  /*padding: 0rem 1rem;*/
}

.toolbar-alert-info {
  @extend .alert-info;
  color: $text-color;
  background-color: $alert-bg-info;
  margin-bottom: 0rem;
  /*padding: 0rem 1rem;*/
}

.toolbar-alert-danger {
  @extend .alert-danger;
  color: $text-color;
  background-color: $alert-bg-danger;
  margin-bottom: 0rem;
  /*padding: 0rem 1rem;*/
}

.toolbar-alert-warning {
  @extend .alert-warning;
  color: $text-color;
  background-color: $alert-bg-warning;
  margin-bottom: 0rem;
  /*padding: 0rem 1rem;*/
}

.toolbar-alert-status {
  @extend .toolbar-alert-success;
  color: $text-color-darker;
}

.toolbar-alert-primary {
  @extend .alert-primary;
  color: $text-color;
  background-color: $alert-bg-primary;
  margin-bottom: 0rem;
  /*padding: 0rem 1rem;*/
}

.toolbar-alert-secondary {
  @extend .alert-secondary;
  color: $text-color;
  background-color: $alert-bg-secondary;
  margin-bottom: 0rem;
  /*padding: 0rem 1rem;*/
}

cc-instructions .toolbar-report {
  margin-bottom: 1rem;
}

.searchBoxText {
  font-style:  italic;
  font-weight:  300;
  color: $text-color;
  border-width: 1px;
  border-color: $nav-link-color;
}

.searchBoxTextActive {
  @extend .searchBoxText;
  color: $text-color-darker;
  font-style: normal;
  font-weight: 500;
}