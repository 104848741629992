// Navbar
.navbar-inverse {
	background-color: $nav-background-color;
	border-color: #d3e0e9;
}
.navbar-inverse .navbar-nav > li > a {
	color: $nav-link-color;
	padding-top: 25px;
	padding-bottom: 25px;
}
.navbar-inverse .navbar-nav > .open > a, .navbar-inverse .navbar-nav > .open > a:hover, .navbar-inverse .navbar-nav > .open > a:focus {
	background-color: #04607A;
}
.navbar-brand img {
  height: 50px;
}

.with-navbar {
}

.navbar-dark {
	background-color: $nav-background-color;
	border-color:$nav-background-color;
}
.navbar-dark .navbar-nav > li > a {
	color: $nav-link-color;
/*	padding-top: 10px;
	padding-bottom: 10px;
*/}
.navbar-dark .navbar-nav .nav-link {
/*  font-size: 1.125rem;
  font-weight: 600;
*/	color: $nav-link-color;
    padding-right: 1.0rem;
    padding-left: 1.0rem;
}
.navbar-dark .navbar-nav > .open > a, .navbar-dark .navbar-nav > .open > a:hover, .navbar-dark .navbar-nav > .open > a:focus {
	background-color: $site-blue-dark-alt;
}

.navbar-icon {
    /*padding-top: 3px;*/
    position: relative;
    font-size: 1.125rem;
}
.navbar-spark i {
	opacity: 1.0;
}
.navbar-icon .activity-indicator {
    width: 13px;
    height: 13px;
    /*background: #bf5329;*/
    background: $green;
    border: 2px solid #fff;
    border-radius: 10px;
    position: absolute;
    right: -1px;
    transition: all .15s;
    -webkit-transform: scale(0.85);
    transform: scale(0.85);
}

.nav > li > a {
    position: relative;
    display: block;
    padding: 10px 15px;
}

.dropdown-item {
    /*color: #636b6f;*/
    font-weight: 300;
}

.dropdown-menu {
    padding-right: 0.5rem;
}

.vertical-nav {
  background-color: $white;
/*  border-right: 1px solid #0B8BB0;
  border-left: 1px solid #0B8BB0;
  border-bottom: 1px solid #0B8BB0;
*/
}

.vertical-nav .nav-link:hover, 
.vertical-nav .nav-link:focus {
  color: $nav-hover-color;
  background-color: darkgray;  
}

.navbar-dark .navbar-nav .nav-link:hover, 
.navbar-dark .navbar-nav .nav-link:focus {
  color: $nav-hover-color;
}

.navbar-nav .nav-item {
  font-size: 1.5rem;
}

.nav-tabs .nav-link,
.nav-tabs .nav-link:hover {
  font-size: 1.0rem;
  border-width: 1px;
  border-style: solid; 
  border-color: #fff #fff #056782 #fff;
  color: #056782;
  background-color: #fff
}

.nav-tabs .nav-link:hover {
  background-color: #f4f5f6;
  color: $text-hover-color;
  /*color: rgb(255, 198, 138);*/
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #056782;
  /*background-color: $nav-tabs-active-background-color;*/
  background-color: $toolbar-background-color;
  border-top-color: #056782;
  border-left-color: #056782;
  border-right-color: #056782;
  border-bottom-color: #056782;
  border-bottom-style: dotted;
}
