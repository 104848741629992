// Footer
#footer {
  clear: both;
  margin: 20px auto;
  padding: 0px;
  text-align: center;
  background-image: url('/img/urbics-logo-web-tiny.jpg');
  background-position: center 25px;
  background-repeat: no-repeat;
  border-top: 1px solid #87B6C3;
}
#footerLinks  li a {
	text-decoration: none;
}
#footer ul {
  list-style-type: none;
  text-align: center;
  display: block;
  margin: 0;
  padding: 85px 0px 0px 0px;
}
#footer ul li {
  font-size: .6em;
  margin:0px;
  padding:0px;
  text-align: center;
  display: inline-block;
}
#footer ul li a {
  text-decoration:none;
  padding:10px;
  margin:0px;
  text-align: center;
  color: #0B8BB0;
}

#footer ul li a:hover {
  color:#F97A00;
}
#footer p {
  text-align: center;
  font-size: 0.6em;
  line-height: 200%;
  color: #87B6C3;
  margin: 0px;
  padding: 20px;
  border-top-width: 1px;
}

.list-footer {
  margin-top: 1.5rem;
}

.list-footer .card {
  border: none;
}
