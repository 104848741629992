// Buttons
.btn-link {
	color: $site-blue-pale;
}

.btn-primary {
	background-color: $site-blue-pale;
	border-color: $site-blue-pale;
}

.btn-info {
	color: $site-white;
}

.btn.width-sm {
	width: 6rem;
}

.btn.width-md {
	width: 8rem;
}

.btn.width-lg {
	width: 10rem;
}
