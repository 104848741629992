.report-list > tbody > tr > td {
  padding: 2px;
  vertical-align: middle;
}

.report-list > tbody > tr > td.report-group {
  color: #0B8BB0;
  font-size: 1em;
  font-weight: 700;
  padding-top: 10px;
  padding-bottom: 0px;
}

.report-list > tbody > tr > td.report-group.first {
  border-top: none;
  padding-top: 0px;
}

.report-list > tbody > tr > td.item-label {
  padding-left: 10px;
  font-size: 1em;
  font-weight: 700;
}

.report-list > tbody > tr > td.report-item {
  padding-left: 1em;
}

.report-list > tbody > tr > td.report-item a {
  color: #808080;
  text-decoration: none;
}

.report-list > tbody > tr > td.report-item a:hover {
  color: #0B8BB0;
}

.report-list > tbody > tr > td.btn {
  padding: 2px;
}
