// Report and Area list styling
ul.report-list {
  line-height: 150%
}

.report-options-list li {
  margin-top: 10px;
}

.status-key {
  margin-top: 10px;
}

.tab-content .row .report-list {
  margin-top: 1em;
}

.no-top-border {
  border-top: none;
}

.group-header {
  color: $text-header-color;
  font-size: 1em;
  font-weight: 700;
  padding-top: 0.5em;
  padding-right: 0px;
  padding-left: 0em;
  padding-bottom: 0.5em;
  margin: 0.5em 0 0 0;
  border-top: 1px solid $border-header-color;
  text-align: left;
}

.report-card .report-group, 
.report-card .report-sub-group,
.report-list li.report-group, 
.report-list li.report-sub-group, 
.report-list span.report-group,
.report-list span.report-sub-group,
.report-table tbody tr td.report-group, 
.report-table tbody tr td.report-sub-group,
.report-table tbody tr td.report-sub-group-2,
.report-table tbody tr td.report-item-group,
.report-list span.report-source-label {
  @extend .group-header;
}

.report-list li.list-group-item.report-group {
  border: none;
  padding: 0.5em 0 0.25 0em;
}

.report-list li.list-group-item.report-item {
  border: none;
  padding: 0 0 0.25em 1em;
}

.report-card .report-group-toggle {
  color: $text-header-color;
  padding: 0.5em 0;
  margin: 0.5em 0 0 0;
  border-top: none;
}

.card-settings {
  padding-bottom:  1rem;
}

.report-card-settings {
  @extend .card-settings;
}

.pricing-card .report-table tbody tr td.report-group {
  padding-bottom:  0px;
}

.report-table tbody tr td.report-sub-group {
  border-top:  none;
}

.report-table tbody tr td.report-item-group {
  color: $text-item-color;
  font-weight: normal;
  border-top: none;
}

.report-table tbody tr td.report-sub-group-2 {
  font-size: 1.0rem;
}

.report-table tbody tr td.label-cell {
  padding: 1em 0em 1em 1em;
  vertical-align: top;
  text-align: left;
  font-weight: bold;
}

.report-table tbody tr td.data-cell {
  @extend .label-cell;
  font-weight: normal;
}

.report-card .report-sub-group,
.report-list li.report-sub-group, 
.report-table tbody tr td.report-group,
.report-table tbody tr td.report-sub-group
.report-table tbody tr td.report-item-group {
  border-top: none;
}

.report-card .report-sub-group,
.report-list li.report-sub-group, 
.report-list span.report-sub-group,
.report-table tbody tr td.report-sub-group,
.report-table tbody tr td.report-sub-group-2,
.report-table tbody tr td.report-item-group {
  margin: 0 0 0 0;
  padding-left: 0.5em;
  padding-bottom: 0;
  font-style: italic;
}

.report-card .report-sub-group.first,
.report-list li.report-group.first, 
.report-list span.report-group.first,
.report-list span.report-source-label {
  border-top: none;
  padding-top: 0px;
  margin-top: 0px;
}

.report-list li.report-group {
  padding-bottom: 0;
}

.card-body-with-table {
  @extend .card-body;
  padding-top: 0;
}

.card-body .report-table tbody tr td.report-group {
  padding-bottom: 0;
  padding-top: 2rem;
}

.card-body .report-table tbody tr td.report-group.first {
  padding-top: 1rem;
}

.report-card .report-group {
  border-top: none;
  padding-top: 0px;
  margin-top: 0px;
  padding-bottom: 0;
}

.report-list li.indent {
  padding-left: 2rem;
}

.report-table tbody tr td.light-border-top {
  border-top: 1px solid $border-header-color-pale;
}

.report-table tbody tr td.last-row,
.report-table tbody tr td.last-row-in-col {
  /*border-bottom: 1px solid $border-header-color;*/
}

.row.report-table {
  padding-bottom: 1em;
  border-bottom: 1px solid $border-header-color;
}

.row.list-table {
  padding-bottom: 1em;
  border-bottom: 1px solid $border-header-color;
}

.list-table div.column-heading,
form div.column-heading {
  color: $text-header-color;
  font-size: 1em;
  font-weight: 700;
  padding-top: 15px;
  padding-right: 0em;
  padding-left: 0em;
  padding-bottom: 10px;
  margin: 15px 0 0px 0;
  border-bottom: 1px solid $border-header-color;
}
.list-table div.column-heading+ul,
form div.column-heading+ul {
  margin-top: 1em;
}
.page-content form div.column-heading {
  padding-top: 0px;
  margin-top: 0px;
}

.base-page-content {
  margin-top: 1.3rem;
}

.report-list li.item-label {
  margin-top: 0px;
  margin-bottom: 0px;
  padding-left: 10px;
  padding-top: 1em;
  font-size: 1em;
  font-weight: 700;
}

.report-list li.report-single-item {
  padding-left: 10px;
  padding-top: 1em;
}

.report-table tbody tr td.item-label,
.report-table tbody tr td.item-label-em {
  text-align: left;
}

.report-table tbody tr td.item-label-em {
  font-style: italic;
}

.report-list li.first {
  padding-top: 0;
}

.report-list li.report-item,
.report-list li.report-sub-item,
.report-list li.report-single-item,
.report-list li.report-sub-group-item,
.report-table tbody tr td.report-item,
.report-table tbody tr td.report-item-em,
.report-table tbody tr td.report-sub-item {
  margin-top: 0px;
  margin-bottom: 0px;
  padding-left: 1em;
}

.report-table tbody tr td.report-item-em {
  font-style: italic;
}

.report-list li.report-footnote-item {
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  padding-left: 1em;
}

.report-list li.report-footnote-item.first {
  margin-top: 0em;
}

.report-list li.report-source-item {
  margin-top: 1em;
  margin-bottom: 1em;
  padding-left: 0em;
}

.report-list span.report-source-label {
  padding-right: 1em;
}

 a {
  color: $text-link-color;
  text-decoration: none;
}

.disabled-link {
  pointer-events:  none;
}

.report-list li.report-item,
.report-list li.report-sub-item,
.report-list li.report-sub-group-item {
  padding-bottom: 0.25em;
}

.report-list li.report-item a,
.report-list li.report-sub-item a,
.report-list li.report-sub-group-item a,
.report-list li.report-footnote-line a,
.report-list li.report-source-item a,
.report-table tbody tr td.report-item a {
  color: $text-item-color;
  text-decoration: none;
}

.report-list li.report-item a:hover,
.report-list li.report-sub-item a:hover,
.report-list li.report-sub-group-item a:hover,
.report-list li.report-footnote-line a:hover,
.report-list li.report-source-item a:hover,
.report-table tbody tr td.report-item a:hover {
  color: $text-hover-color;
}

.report-list li.report-sub-item,
.report-list li.report-sub-group-item,
.report-table tbody tr td.report-sub-item {
  padding-top: 0;
  margin-left: 1em;
}

.report-list li.report-single-item {
  padding-top: 1em;
}


.report-list li.report-sub-group-item {
    padding-left: 1.75em;
}

.content-title .title-text, .content-title-pdf .title-text{
  line-height: 150%;
  color:  $site-blue;
  text-align: center;
  font-size: 1.3em;
  /*margin-top: 1em;*/
  margin-bottom: 1em;
  /*text-align: left;*/
  /*font-size: 1.6em;*/
}

.section-content, .section-title, .sub-section-title, .icon-key-section-title {
  margin: 0rem 0rem 1.0rem 0rem;
}

p.instruction {
  margin-bottom: 0rem;
}

.section-desc {
  /*line-height: 120%;*/
  color: $text-item-color;
  text-align: left;
  font-size: 1.0em;
  margin-top: 1em;
  margin-bottom: 1em;
}

.section-title, .sub-section-title, .icon-key-section-title {
  line-height: 150%;
  color:  $site-blue;
  text-align: left;
  font-size: 1.2em;
  border-bottom: 1px solid #ddd;  
}

.icon-key-section-title {
  text-align: center;
}

.sub-section-title {
  line-height: 125%;
  font-size: 1.0em;
  border-bottom: 0px;  
  margin: 0.75rem 0rem 0.75rem 0rem;
}

h3.section-title{
	color:  $site-blue;
	font-size: 1em;
  font-weight: 700;
	padding-top: 0px;
	margin: 0px;
}

.tab-content .section-title,
.vue-content .section-title {
  text-align: center;
  border-bottom: 0px;  
}

.report-card .section-title {
  font-size: 1.5rem;
  text-align: center;
}

.report-card .section-title.no-bottom-line {
  border-bottom: 0px;
}

.callout-title {
  width: auto;
  background-color: #FFCE9F;
  font-size: 1.5em;
  font-weight: 500;
  color:  $site-blue;
  padding: 12px 10px 10px 10px;
  margin: 0px;
  border-radius: 5px;  
}

table.report-table {
  font-size: 0.9em;
  font-weight: lighter;
  text-align: left;
  color: $text-item-color;
  background-color: transparent;
  margin: 15px 0px 0px 0px;
  width: 100%;
  padding-top: 0px;
}

.report-table > thead > tr > th {
  color:  $site-blue;
  padding: 0.25rem 0.5rem;
  border-bottom: 1px solid  $site-blue;
  text-align: center;
  margin: 0px;
}

.report-table thead tr th.no-bottom-line {
  border-bottom: none;
}

.report-table.with-bottom-border {
  border-bottom: 1px solid  $site-blue;
}


.report-table > thead > tr > th.pad-sides {
  padding-left: 1em;
  padding-right: 1em;
}

.report-table thead th {
  background-color: inherit;
  font-size: inherit;
  font-weight: bold;
}

.table > caption + thead > tr:first-child > th,
.table > colgroup + thead > tr:first-child > th,
.table > thead:first-child > tr:first-child > th,
.table > caption + thead > tr:first-child > td,
.table > colgroup + thead > tr:first-child > td,
.table > thead:first-child > tr:first-child > td {
  border-top: 0;
}
.report-table tbody tr th.th2 {
  border-style: none;
  border-width: 0px;
  border-color: black;
  background-color: #87B6C3;
  color: #FFFFFF;
  padding-top: 6px;
  padding-right: 4px;
  padding-bottom: 4px;
  padding-left: 4px;
  width: auto;
  margin: 0px;
  font-weight: normal;
}

.report-table tbody tr td {
  padding-top: 5px;
  padding-left: 0px;
  padding-bottom: 0px;
  padding-right: 3px;
  margin: 0;
  border-style: none;
  border-width: 0px;
  border-color: black;
  color: $text-item-color;
  font-weight: bolder;
  text-align: right;
}

.report-table tbody tr td.boldText,
.report-table tbody tr td.bold-text {
  font-weight: bold;
}

.report-table tbody tr td.normalText,
.report-table tbody tr td.normal-text {
  font-weight: normal;
}

.report-table tbody tr td a {
    text-decoration: none;
}

.report-table tbody tr td a:hover {
    text-decoration: none;
    color: $text-hover-color;
    /*color: #FF9F43;*/
}

.report-table tbody tr td.top-border {
  margin: 0px;
  padding: 0px;
  border-top: 1px solid #87B6C3;
}

.report-table tbody tr td.pad-top,
.report-table tbody tr td.padTop {
    padding-top: 1em;
}

.report-table tbody tr td.pad-right,
.report-table tbody tr td.padRight {
    text-align: right;
    padding-right: 1em;
}

.report-table tbody tr td.pad-bottom,
.report-table tbody tr td.padBottom {
    padding-bottom: 1em;
}

.report-table tbody tr td.pad-left,
.report-table tbody tr td.padLeft {
    text-align: left;
    padding-left: 1em;
}

.report-table tbody tr td.pad-top-more,
.report-table tbody tr td.padTopMore {
    padding-top: 2em;
}

.report-table tbody tr td.pad-right-more,
.report-table tbody tr td.padRightMore {
    text-align: right;
    padding-right: 2em;
}

.report-table tbody tr td.pad-bottom-more,
.report-table tbody tr td.padBottomMore {
    padding-bottom: 2em;
}

.report-table tbody tr td.pad-left-more,
.report-table tbody tr td.padLeftMore {
    text-align: left;
    padding-left: 2em;
}

.report-table tbody tr td.pad-top-triple,
.report-table tbody tr td.padTopTriple {
    padding-top: 3em;
}

.report-table tbody tr td.pad-right-triple,
.report-table tbody tr td.padRightTriple {
    text-align: right;
    padding-right: 3em;
}

.report-table tbody tr td.pad-bottom-triple,
.report-table tbody tr td.padBottomTriple {
    padding-bottom: 3em;
}

.report-table tbody tr td.pad-left-triple,
.report-table tbody tr td.padLeftTriple {
    text-align: left;
    padding-left: 3em;
}

.report-table tbody tr td.alignTop {
    vertical-align: top
}

.report-table tbody tr td.center {
  text-align: center;
}

.report-table tbody tr td.top-align {
  vertical-align: text-top;
}

.report-table tbody tr.total td {
    border-top: 3px double $border-header-color-pale;
}

.report-table tbody tr td.total {
    border-top: 3px double $border-header-color-pale;
}

.report-table tbody tr.sub-total td {
    border-top: 1px solid $border-header-color-pale;
}

.report-table tbody tr.group-separator td {
    border-top: 3px double $border-header-color-pale;
}

.report-table tbody tr td.group-separator {
    border-top: 3px double $border-header-color-pale;
}

.report-table tbody tr.sub-group-separator td {
    border-top: 3px double $border-header-color-darker;
}

.report-table tbody tr td.sub-total {
    border-top: 1px solid $border-header-color-pale;
  }

.report-table tbody tr td.sub-group-separator {
    border-top: 3px double $border-header-color-darker;
}

/* Table cell width */
th.width-100,
td.width-100,
colgroup.width-100 {
  width: 100%;
}

th.width-90,
td.width-90,
colgroup.width-90 {
  width: 90%;
}

th.width-80,
td.width-80,
colgroup.width-80 {
  width: 80%;
}

th.width-75,
td.width-75,
colgroup.width-75 {
  width: 75%;
}

th.width-70,
td.width-70,
colgroup.width-70 {
  width: 70%;
}

th.width-66,
td.width-66,
colgroup.width-66 {
  width: 66%;
}

th.width-60,
td.width-60,
colgroup.width-60 {
  width: 60%;
}

th.width-50,
td.width-50,
colgroup.width-50 {
  width: 50%;
}

th.width-45,
td.width-45,
colgroup.width-45 {
  width: 45%;
}

th.width-40,
td.width-40,
colgroup.width-40 {
  width: 40%;
}

th.width-33,
td.width-33,
colgroup.width-33 {
  width: 33%;
}

th.width-30,
td.width-30,
colgroup.width-30 {
  width: 30%;
}

th.width-25,
td.width-25,
colgroup.width-25 {
  width: 25%;
}

th.width-20,
td.width-20,
colgroup.width-20 {
  width: 20%;
}

th.width-15,
td.width-15,
colgroup.width-15 {
  width: 15%;
}

th.width-12-5,
td.width-12-5,
colgroup.width-12-5 {
  width: 12.5%;
}

th.width-10,
td.width-10,
colgroup.width-10 {
  width: 10%;
}

th.width-6-25,
td.width-6-25,
colgroup.width-6-25 {
  width: 6.25%;
}

th.width-5,
td.width-5,
colgroup.width-5 {
  width: 5%;
}

.report-table tbody tr td.widthOneThird {
  width: 33%;
}

.report-table tbody tr td.widthTwoThirds {
  width: 66%;
}

/* Report Table vertical alignment */
.report-table > thead > tr > th {
  vertical-align: bottom;
}

.text-middle {
  display: flex;
  align-items:  center;  
}

.table > tbody > tr > th, 
.table > tfoot > tr > th, 
.table > thead > tr > td, 
.table > tbody > tr > td, 
.table > tfoot > tr > td {
  vertical-align: middle;
}

/* Form */
.form-group label.boldText {
  font-weight: bold; 
}

.form-group .btn-min-width {
  min-width: 12rem;
}

/* Filter */
.filter-shortcut-item a.list-group-item,
.sidebar-item a.list-group-item {
  padding: 0.25rem 1rem;
}
.filter-shortcut-item .list-group-item:last-child,
.sidebar-item .list-group-item:last-child {
  margin-bottom: 1rem;
}
